import * as btnStyles from './Btn.css';
import { createUseRecipeHook, PropsWithClassName, RecipeVariants } from '@/styles';

const useBtnStyleRecipe = createUseRecipeHook(btnStyles.root);

export const useBtnStyle = <P extends PropsWithClassName<BtnStyleProps>>(props: P) => {
  return useBtnStyleRecipe(props);
};

export type BtnStyleProps = RecipeVariants<typeof btnStyles.root>;

export { btnStyles };
