export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.42, // 3:2
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.54, // 16:9
  VIDEO = 0.5625, // 16:9
}

export const ratios = {
  square: `${IMG_RATIO.SQUARE * 100}%`,
  portrait: `${IMG_RATIO.PORTRAIT * 100}%`,
  landscape: `${IMG_RATIO.LANDSCAPE * 100}%`,
  landscapeTall: `${IMG_RATIO.LANDSCAPE_TALL * 100}%`,
  landscapeWide: `${IMG_RATIO.LANDSCAPE_WIDE * 100}%`,
  video: `${IMG_RATIO.VIDEO * 100}%`,
};

export type Ratio = keyof typeof ratios;
