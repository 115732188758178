import { pxToRem } from './common';
import { Breakpoint, breakpoints } from './breakpoints';

type ValueClampProps = [
  minSizePx: number,
  maxSizePx: number,
  breakpointMin?: number | Breakpoint,
  breakpointMax?: number | Breakpoint,
  useRem?: boolean // New prop to toggle pxToRem conversion
];

export default function cssFluidSize(props: ValueClampProps): string {
  const [minSizePx, maxSizePx, breakpointMin = 'md', breakpointMax = 'xl', useRem = true] = props;

  const breakpointMinPx =
    typeof breakpointMin === 'number' ? breakpointMin : breakpoints[breakpointMin];
  const breakpointMaxPx =
    typeof breakpointMax === 'number' ? breakpointMax : breakpoints[breakpointMax];

  const diff = maxSizePx - minSizePx;
  const screenDiff = breakpointMaxPx - breakpointMinPx;

  const slope = diff / screenDiff;

  const convert = (value: number) => (useRem ? pxToRem(value) : value);

  if (minSizePx === maxSizePx || slope <= 0) return `${convert(minSizePx)}`;

  const slopePercentage = slope * 100;
  const slopeU = `${slopePercentage.toFixed(3)}vw`;

  const yIntersection = Number((-breakpointMinPx * slope + minSizePx).toFixed(3));

  const calcStr = `calc(${convert(yIntersection)} + ${slopeU})`;

  return `clamp(${convert(minSizePx)}, ${calcStr}, ${convert(maxSizePx)})`;
}
