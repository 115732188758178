import { isArray, isString } from '@liquorice/utils';
import { ComplexStyleRule, style, StyleRule } from '@vanilla-extract/css';
import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';

/**
 * Convert the number of pixels into a rem value string
 */
export const pxToRem = (px: number) => (px ? `${px / 16}rem` : '0');
export const numberToPx = (px: number) => (px ? `${px}px` : '0');

type PxValues = {
  [x: string]: string | number | PxValues;
};

type StringValues<T extends PxValues> = {
  [P in keyof T]: T[P] extends PxValues ? StringValues<T[P]> : string;
};

export const mapPxValues = <T extends PxValues, Callback extends (v: number) => string>(
  values: T,
  callback: Callback
): StringValues<T> => {
  return mapObject(values, (value) => {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return callback(value);
    return mapPxToRem(value);
  }) as StringValues<T>;
};

export const mapPxToRem = <T extends PxValues>(pxValues: T): StringValues<T> => {
  return mapPxValues(pxValues, pxToRem);
};

export const mapPxToStrings = <T extends PxValues>(pxValues: T): StringValues<T> => {
  return mapPxValues(pxValues, numberToPx);
};

// type TransitionType = keyof typeof transition.type;
// export const mergeTransitions = (...types: TransitionType[]) =>
// types.map((v) => vars.transition.type[v]).join(', ');

/**
 * Add vars to an existing style rule
 */
export const withVars = <T extends Record<string, string>, U extends StyleRule>(
  style: U | null,
  ...vars: [T, ...T[]]
): StyleRule => ({
  ...style,
  vars: {
    ...style?.vars,
    ...vars.reduce((result, item) => ({ ...result, ...item })),
  },
});

/**
 * Merge Style Rules into a single object. Later rules take precedence
 */
export const combineStyleRules = <T extends StyleRule>(...styles: [T, ...T[]]): StyleRule => ({
  ...styles.reduce((result, item) => ({ ...result, ...item })),
});
/**
 * Extract the `vars` from n StyleRule object
 * and create an empty StyleRule with all vars combine
 */
export const extractVars = <T extends StyleRule>(...styles: [T, ...T[]]): StyleRule => ({
  vars: {
    ...styles.reduce((result, item) => ({ ...result, ...item.vars }), {} as StyleRule['vars']),
  },
});

type StyleVars = NonNullable<StyleRule['vars']>;
type VarsOnlyStyle = Required<Pick<StyleRule, 'vars'>>;
type StyleVariantVars<T extends string> = Record<T, VarsOnlyStyle>;

export const createVariantVars = <T extends string>(variants: Record<T, StyleVars>) => {
  return mapObject(variants, (vars) => ({ vars })) as StyleVariantVars<T>;
};

type StyleVariantClassNames = {
  /** ClassName to apply style */
  style: string;
  /** ClassName to apply vars */
  vars: string;
};

/**
 * Create a map of {@link StyleVariantClassNames} objects for each variant
 */
export const createVariantsStylesAndVars = <VariantNames extends string>(
  styles: Record<VariantNames, ComplexStyleRule>
): Record<VariantNames, StyleVariantClassNames> =>
  mapObject(styles, (variantStyleRules) => {
    const stylesArr = normaliseComplexStyleRule(variantStyleRules);

    const mergedStyles = stylesArr.reduce(
      (result, rules) => {
        if (isString(rules)) return result;
        const { vars, ...style } = rules;
        return {
          style: [...result.style, style],
          vars: { ...result.vars, ...vars },
        };
      },
      {
        style: [],
        vars: {},
      } as {
        style: (StyleRule | string)[];
        vars: StyleVars;
      }
    );

    // Return a combined style with the vars separated
    return {
      style: style(mergedStyles.style),
      vars: style(withVars(null, mergedStyles.vars)),
    };
  });

const normaliseComplexStyleRule = <
  T extends ComplexStyleRule | StyleRule | string | (StyleRule | string | string[])[]
>(
  rule: T
): (StyleRule | string)[] => {
  const result: (StyleRule | string)[] = [];

  if (isString(rule) || !isArray(rule)) return [rule];
  const flatRules = rule.flat(3) as (StyleRule | string)[];
  result.concat(...flatRules);

  return result;
};
