import { keys } from '@liquorice/utils';
import { Breakpoint, BreakpointAlias, getBreakpointValue } from './breakpoints';
import { CSSProperties, StyleRule } from '@vanilla-extract/css';

type CSSPropertyKey = keyof CSSProperties;

export type ResponsiveStyleRule<T> = { [P in Breakpoint]?: T };

export type ResponsiveProperty<T extends CSSPropertyKey> = { [P in Breakpoint]?: CSSProperties[T] };

export const isResponsiveProperty = <T extends CSSPropertyKey>(
  x?: ResponsiveProperty<T> | CSSProperties[T]
): x is ResponsiveProperty<T> => {
  if (x ?? null === null) return false;
  return true;
};

export const getBreakpointConditions = <
  Breakpoint extends string,
  T extends Record<string, number>
>(
  breakpoints: T
) =>
  keys(breakpoints).reduce((result, breakpoint) => {
    const value = breakpoints[breakpoint];
    return {
      ...result,
      [breakpoint]: value ? { '@media': `screen and (min-width: ${value}px)` } : {},
    };
  }, {} as Record<Breakpoint, { '@media'?: string }>);

// export type ResponsiveCSSProperties = { [P in CSSPropertyKey]?: ResponsiveStyleRule<P> };

type ResponsiveStyles = { [P in Breakpoint | BreakpointAlias]?: StyleRule };

export const responsiveStyles = <T extends StyleRule>(
  props: ResponsiveStyles,
  base?: T
): StyleRule => {
  let defaultRules: StyleRule = {
    ...base,
  };

  const queries = {
    ['@media']: keys(props).reduce((result, breakpoint) => {
      const px = getBreakpointValue(breakpoint);
      const bpRules = props[breakpoint] ?? {};
      if (!bpRules) return result;

      if (px === 0) {
        defaultRules = { ...defaultRules, ...bpRules };
        return result;
      }

      return {
        ...result,
        [`screen and (min-width: ${px}px)`]: bpRules,
      };
    }, {} as Record<string, StyleRule>),
  };

  return {
    ...defaultRules,
    ...queries,
  };
};

export { responsiveStyles as rSx };
